.technologies {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.technologies span {
  color: var(--ivory);
  background-color: var(--navy);
  padding: 0.5rem 0.75rem;
  font-family: 'Montserrat Bold', sans-serif;
}
