.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 3rem;
  width: 100%;
  padding: 2.5rem 7.5rem;
}

/* Overview Styling */
.overview {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.overview h4 {
  color: var(--ivory);
  font-size: var(--fs-lg);
  font-family: 'Montserrat Black', sans-serif;
  margin-bottom: 0.5rem;
}

.overview span {
  color: var(--ivory);
  font-size: var(--fs-sm);
  font-family: 'Montserrat Light', sans-serif;
  margin-bottom: 0.5rem;
}

.overview a {
  color: var(--ivory);
  font-size: var(--fs-sm);
  font-family: 'Montserrat Bold', sans-serif;
  text-decoration: none;
}

/* Social Styling */
.social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.socials {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.socials svg {
  width: 3rem;
  height: 3rem;
  fill: var(--ivory);
  color: var(--ivory);
  transition: all 300ms ease-in-out;
}

.socials svg:hover {
  fill: var(--orange);
  color: var(--orange);
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

.socials svg:first-child {
  width: 2.9rem;
  height: 2.9rem;
}

/* Media Query for Mobile Devices below 576px in width */
@media (max-width: 576px) {

  .footer {
    flex-direction: column;
    gap: 1.5rem;
    padding: 2.5rem;
  }

}

/* Media Query for Tablet Devices above 577px and below 992px in width */
@media (min-width: 577px) and (max-width: 992px) {

  .footer {
    padding: 3rem 5rem;
  }

}
