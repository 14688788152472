.blogList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 1.5rem;
  background-color: var(--ivory);
}

.blogList h2 {
  color: var(--navy);
  font-size: var(--fs-lg);
  font-family: 'Montserrat', sans-serif;
}

/* Styling for marker underline */
.blogList h2 {
  position: relative;
  z-index: 1;
}

.blogList h2::before {
  content: "";
  position: absolute;
  left: -0.2rem;
  bottom: -0.2rem;
  width: calc(100% + 0.4rem);
  height: 1rem;
  transform: skew(-12deg);
  background: var(--orange);
  z-index: -1;
}

.blogContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

/* Button Styling */
.allBlogs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
  text-decoration: none;
  cursor: pointer;
  align-self: flex-end;
}

.allBlogs span {
  color: var(--navy);
  font-size: var(--fs-md);
  font-weight: bold;
  text-decoration: none;
  font-family: 'Montserrat Bold', sans-serif;
  margin-bottom: 0;
}

.allBlogs svg {
  fill: var(--navy);
  width: var(--fs-md);
  height: var(--fs-md);
}


/* Media Query for Mobile Devices below 576px in width */
@media (max-width: 576px) {

  .blogContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

}

/* Media Query for Tablet Devices above 577px and below 992px in width */
@media (min-width: 577px) and (max-width: 992px) {

  .blogContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

}
