.logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.25rem;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.logo span {
  color: var(--navy);
  font-size: calc(var(--fs-base) - 0.15rem);
  font-family: 'Montserrat', sans-serif;
}

.logo svg {
  height: 3.25rem;
  width: 3.25rem;
  transition: all 300ms ease-in-out;
}

.logo svg:hover {
  transform: scale(1.1);
  transition: all 300ms ease-in-out;
}
