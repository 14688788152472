.home {
  position: relative;
  width: 100%;
}

.content {
  padding: 5rem 7.5rem;
  background-color: var(--ivory);
}

/* Media Query for Mobile Devices below 576px in width */
@media (max-width: 576px) {

  .home {
  }

  .content {
    padding: 3rem 2.5rem;
  }

}

/* Media Query for Tablet Devices above 577px and below 992px in width */
@media (min-width: 577px) and (max-width: 992px) {

  .content {
    padding: 5rem;
  }

}
