.projectList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 5rem;
  margin-bottom: 5rem;
  background-color: var(--ivory);
}

.projectList h2 {
  color: var(--navy);
  font-size: var(--fs-lg);
  font-family: 'Montserrat', sans-serif;
}

/* Reduce Gap */
.projectList > :nth-child(2) {
  margin-top: -3.5rem;
}

/* Styling for marker underline */
.projectList h2 {
  position: relative;
  z-index: 1;
}

.projectList h2::before {
  content: "";
  position: absolute;
  left: -0.2rem;
  bottom: -0.2rem;
  width: calc(100% + 0.4rem);
  height: 1rem;
  transform: skew(-12deg);
  background: var(--orange);
  z-index: -1;
}

/* Media Query for Mobile Devices below 576px in width */
@media (max-width: 576px) {

  .projectList {
    gap: 3rem;
  }

  /* Reduce Gap */
  .projectList > :nth-child(2) {
    margin-top: -1.5rem;
  }

}

/* Media Query for Tablet Devices above 577px and below 992px in width */
@media (min-width: 577px) and (max-width: 992px) {

}
