.blogOverview {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.blogOverview h4 {
  color: var(--navy);
  font-size: var(--fs-md);
  font-family: 'Montserrat Black', sans-serif;
  margin-bottom: 1rem;
}

.blogOverview span {
  color: var(--navy);
  font-size: var(--fs-base);
  font-family: 'Montserrat Light', sans-serif;
  margin-bottom: 0.25rem;
}

.blogOverview p {
  color: var(--navy);
  font-size: var(--fs-base);
  line-height: calc(var(--fs-base) + 0.25rem);
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 1rem;
}

/* Button Styling */
.button span {
  color: var(--orange);
  font-size: var(--fs-base);
  font-family: 'Montserrat Bold', sans-serif;
  font-weight: bold;
  text-decoration: none;
}

/* Animated Underline Styling */
.button {
  position: relative;
  text-decoration: none;
  display: inline-block;
  margin-top: auto;
}

.button:after {
  display: block;
  content: '';
  border-bottom: 2px solid var(--orange);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 100% 50%;
}

.button:hover:after {
  transform: scaleX(1);
  transform-origin: 0 50%;
}

/* Media Query for Mobile Devices below 576px in width */
@media (max-width: 576px) {

  .blogOverview h4 {
    margin-bottom: 0.5rem;
  }

}

/* Media Query for Tablet Devices above 577px and below 992px in width */
@media (min-width: 577px) and (max-width: 992px) {

  .blogOverview h4 {
    margin-bottom: 0.5rem;
  }

}
