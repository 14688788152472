.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  background-color: var(--navy);
  font-family: 'Montserrat', sans-serif;
}

.introContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.75rem;
  padding: 7.5rem;
}

/* Heading Styling (including show & hide) */
.introContent h1,
.introContent h2,
.introContent h3 {
  color: var(--ivory);
  font-family: 'Montserrat', sans-serif;
}

.introContent h1 {
  font-size: var(--fs-xxxl)
}

.introContent h2 {
  font-size: var(--fs-xxxl)
}

.introContent h3 {
  font-size: var(--fs-xl);
  font-family: 'Montserrat Light', sans-serif;
}

.introContent h2,
.introContent h3 {
  display: none;
}

.introContent > span {
  color: var(--ivory);
  font-size: var(--fs-md);
  line-height: calc(var(--fs-md) + 0.25rem);
  font-family: 'Montserrat Light', sans-serif;
  width: 60%;
}

/* CV Styling */
.cv {
  color: var(--orange);
  font-size: var(--fs-md);
  text-decoration: none;
  font-family: 'Montserrat Bold', sans-serif;
  margin-top: 0.25rem;
}

/* Animated Underline Styling */
.cv {
  position: relative;
  text-decoration: none;
  display: inline-block;
}

.cv:after {
  display: block;
  content: '';
  border-bottom: 1px solid var(--orange);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 100% 50%;
}

.cv:hover:after {
  transform: scaleX(1);
  transform-origin: 0 50%;
}

/* Socials Styling */
.socials {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.socials svg {
  width: 3rem;
  height: 3rem;
  fill: var(--orange);
  color: var(--orange);
  transition: all 300ms ease-in-out;
}

.socials svg:hover {
  transform: scale(1.1);
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

.socials svg:first-child {
  width: 2.9rem;
  height: 2.9rem;
}

/* Media Query for Mobile Devices below 480px in width */
@media (max-width: 576px) {

  .introContent {
    padding: 2.5rem;
    gap: 1rem;
  }

  .introContent > span {
    width: 100%;
  }

  /* Show & Hide Headings */
  .introContent h1 {
    display: none;
  }

  .introContent h2,
  .introContent h3 {
    display: block;
  }

}

/* Media Query for Tablet Devices above 577px and below 992px in width */
@media (min-width: 577px) and (max-width: 992px) {

  .introContent {
    padding: 5rem;
    gap: 1rem;
  }

  .introContent > span {
    width: 90%;
  }

  /* Show & Hide Headings */
  .introContent h1 {
    display: none;
  }

  .introContent h2,
  .introContent h3 {
    display: block;
  }

}
