.project {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding: 5rem 7.5rem;
  padding-top: 7.5rem;
  color: var(--navy);
  background-color: var(--ivory);
  font-family: 'Montserrat', sans-serif;
}

/* Header Styling */
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.name {
  color: var(--navy);
  font-size: var(--fs-xxl);
  font-family: 'Montserrat Black', sans-serif;
  margin-right: auto;
}

.header a {
  display: grid;
  place-items: center;
  padding: 0.75rem 1.5rem;
  color: var(--ivory);
  font-size: var(--fs-base);
  text-decoration: none;
  text-align: center;
  font-family: 'Montserrat Bold', sans-serif;
  background-color: var(--orange);
  border: 3px solid var(--orange);
  transition: all 300ms ease-in-out;
}

.header a:hover {
  color: var(--orange);
  background-color: var(--ivory);
  border: 3px solid var(--orange);
  transition: all 300ms ease-in-out;
}

/* Summary Styling */
.summary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.description {
  font-size: var(--fs-md);
}

/* Image Styling */
.largeImage {
  display: grid;
  place-items: center;
  max-width: 100%;
  border-radius: 0.5rem;
}

/* Add handling for smaller screens */
.screenshots > div {
  display: flex;
  flex-direction: row;
  gap: 5%;
  max-width: 100%;
  margin-top: 1rem;
}

.screenshots img {
  width: 30%;
}

.todo,
.goals,
.general,
.technical,
.screenshots {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.todo h2,
.goals h2,
.general h2,
.technical h2,
.screenshots h2 {
  color: var(--navy);
  font-size: var(--fs-lg);
  font-family: 'Montserrat Bold', sans-serif;
  margin-bottom: 1rem;
}

/* Styling for marker underline */
.todo h2,
.goals h2,
.general h2,
.technical h2,
.screenshots h2 {
  position: relative;
  z-index: 1;
}

.todo h2::before,
.goals h2::before,
.general h2::before,
.technical h2::before,
.screenshots h2::before {
  content: "";
  position: absolute;
  left: -0.2rem;
  bottom: -0.2rem;
  width: calc(100% + 0.4rem);
  height: 1rem;
  transform: skew(-12deg);
  background: var(--orange);
  z-index: -1;
}

.todo li,
.todo span,
.goals p,
.general p,
.technical p {
  font-size: var(--fs-base);
  line-height: calc(var(--fs-base) + 0.4rem);
  margin-bottom: 0.5rem;
}

.todo li {
  display: list-item;
  list-style-type: disc;
  margin-left: 1.5rem;
}

/* Project Page Breakpoints are different to other pages (750px vs 576px) */

/* Media Query for Mobile Devices below 750px in width */
@media (max-width: 750px) {
  /* Page Styling */
  .project {
    padding: 2.5rem;
    padding-top: 7.5rem;
  }

  /* Header Styling */
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .name {
    font-size: var(--fs-xxxl);
    margin-right: 0;
  }

  .header a {
    width: 100%;
    padding: 0.75rem 1.5rem;
  }

  /* Summary Styling to reduce gap */
  .summary {
    margin-top: -1rem;
  }

  /* Screenshots Styling */
  .screenshots > div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 100%;
  }

  .screenshots img {
    width: 100%;
    max-width: 100%;
  }

}

/* Media Query for Tablet Devices above 750px and below 992px in width */
@media (min-width: 750px) and (max-width: 992px) {

  .project {
    padding: 5rem;
    padding-top: 7.5rem;
  }

}
