.projectTab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.overview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
  max-width: 45%;
}

.image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40%;
  max-width: 40%;
}

.image img {
  max-width: 100%;
  border-radius: 1rem;
  transition: all 200ms ease-in-out;
}

.image img:hover {
  transform: scale(1.025);
  box-shadow: 0rem 0rem 2rem 1rem var(--shadow);
  transition: all 200ms ease-in-out;
}

.name,
.overview h4,
.overview p {
  color: var(--navy);
}

.overview h4 {
  font-size: var(--fs-sm);
  margin-bottom: 0.5rem;
  font-family: 'Montserrat Light', sans-serif;
  text-transform: uppercase;
}

.name {
  font-size: var(--fs-xxl);
  font-family: 'Montserrat Black', sans-serif;
  margin-bottom: 1rem;
}

.overview p {
  font-size: var(--fs-base);
  font-family: 'Montserrat', sans-serif;
  line-height: calc(var(--fs-base) + 0.25rem);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.overview a {
  text-decoration: none;
}

/* Button Styling */
.button {
  color: var(--ivory);
  text-decoration: none;
  font-size: var(--fs-base);
  font-family: 'Montserrat Bold', sans-serif;
  background-color: var(--orange);
  padding: 1.25rem 2.5rem;
  border: none;
  border: 3px solid var(--orange);
  transition: all 300ms ease-in-out;
}

.button:hover {
  color: var(--orange);
  background-color: var(--ivory);
  border: 3px solid var(--orange);
  transition: all 300ms ease-in-out;
}


@media (max-width: 576px) {

  .projectTab {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .overview {
    width: 100%;
    max-width: 100%;
  }

  .image {
    display: none;
    width: 100%;
    max-width: 100%;
  }

}

/* Media Query for Tablet Devices above 577px and below 992px in width */
@media (min-width: 577px) and (max-width: 992px) {

  .projectTab {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .overview {
    width: 100%;
    max-width: 100%;
  }

  .image {
    display: none;
    width: 100%;
    max-width: 100%;
  }

}
