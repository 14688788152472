.notFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 5rem;
  background-color: var(--navy);
  font-family: 'Montserrat', sans-serif;
}

.notFound h2 {
  color: var(--orange);
  font-size: calc(var(--fs-xxxl) + 2rem);
  font-family: 'Montserrat Black', sans-serif;
  margin-bottom: 0.5rem;
}

.notFound span {
  color: var(--ivory);
  font-size: var(--fs-lg);
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 2.5rem;
}

.notFound a {
  color: var(--ivory);
  font-size: var(--fs-lg);
  text-decoration: none;
  font-family: 'Montserrat Bold', sans-serif;
  transition: all 250ms ease-in-out;
}

.notFound a:hover {
  transform: scale(1.05);
  transition: all 250ms ease-in-out;
}

.notFound h2,
.notFound span,
.notFound a {
  text-align: center;
}

/* Media Query for Mobile Devices below 576px in width */
@media (max-width: 576px) {

  .notFound {
    padding: 5rem 2.5rem;
  }

}
